.rdg-header-row{
    font-weight: bold;
    line-height: var(--rdg-header-row-height);
}
  
.rdg-row{
    line-height: var(--rdg-row-height);
}
  
#userlist-datagrid > .rdg{
    height: calc(100vh - 300px);
    margin: 0 calc(50% - 50vw + 30px)
}