.product-info-box img {
    width: 110px;
  }
  
  .product-info-box .slogan-label {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 100%;
  }
  
  .product-info-box .btn {
    float: left;
  }
  
  .product-info-box > .btn {
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .product-info-box {
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 20px;
  }

  .info-box h3 {
    margin: 15px 0 0 0;
  }
  
  .info-box {
    border: 2px solid var(--text-color);
    padding: 15px;
  }

  .info-box img {
    margin-right: 5px;
  }
  
.info-box .float-left {
    float: left;
  }

  .price-label {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 115%;
  }