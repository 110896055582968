@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light'), local('OpenSans-Light'), url('../assets/fonts/open-sans-300-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'), url('../assets/fonts/open-sans-300-italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url('../assets/fonts/open-sans-600-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url('../assets/fonts/open-sans-600-italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*
	Catalyst by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

:root {
  --page-background: #fff;
  --page-background-alt: #fafafa;
  --text-color: #000;
  --alt-text-color: #FFF;
  --link-color: #920f1e;
  --header-color: #2a2a2a;
  --caption-gray: #757575;
  --accent-gray: #eee;
  --transparent-gray-accent: rgba(59, 35, 35, 0.05);
  --transparent-button-hover: rgba(144, 144, 144, 0.05);
  --articel-footer: #5a5a5a;
  --footer-background: #1d2726;
  --footer-text: #8C8C8C;
  --footer-social: #666;
  --footer-social-hover: #fff;
  --error-color: darkred;
  --error-background: rgba(218, 70, 70, 0.726);
  --success-color: green;
  --success-background: rgba(145, 221, 126, 0.726);
  --notice-color: darkgray;
  --notice-background:  rgba(128, 128, 128, 0.726);
  --form-background: white;
  --form-disabled: lightgray;
  --button-hover: #283634;
  --image-footer: rgba(29, 39, 38, 0.75);
  --nav-text: #ccc;
  --light-gray-background: #e6e6e6;
  --primary: red;
}

@media (prefers-color-scheme: dark) {
  :root {
    --page-background: rgb(27, 27, 27);
    --page-background-alt: #252525;
    --accent-gray: rgb(204, 204, 204);
    --text-color: rgb(238, 230, 230);
    --alt-text-color: black;
    --header-color: #dfdfdf;
    --footer-background: #444240;
    --footer-text: var(--footer-social);
    --footer-social: rgb(214, 214, 214);
    --footer-social-hover: rgb(218, 218, 218);
    --articel-footer: #9c9c9c;
    --form-background: rgb(27,27,27);
    --transparent-button-hover: rgba(73, 73, 73, 0.05);
    --form-disabled: rgb(95, 94, 94);
    --button-hover: #444240;
    --nav-text: var(--footer-social);
    --light-gray-background: var(--footer-background);
  }

  *{
    box-sizing: border-box;
  }
  
  .extern-link::after {
    filter: invert(1);
  }

  #main a {
    font-weight: 600;
  }

  .wp-block-table.is-style-stripes tbody tr:nth-child(2n+1){
    background: var(--footer-background);
  }

  .mail-body {
    color: black;
  }
}

@import url("css-reset.css");

.formerror{
  background-color: var(--error-background);
  display: block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0px 0px 1em 0px;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  appearance: none;
}

/* Basic */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background: var(--page-background);
  transition: background 0.2s ease-in-out;
}

body.is-preload *,
body.is-preload *::before,
body.is-preload *::after {
  animation: none !important;
  transition: none !important;
}

body,
input,
select,
textarea {
  color: var(--text-color);
  transition: color 0.2s ease-in-out;
  font-family: "Open Sans", sans-serif;
  font-size: 13pt;
  font-weight: 300;
  line-height: 1.75em;
}

a {
  color: var(--link-color);
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

strong,
b {
  font-weight: 600;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

.content p{
  text-align: justify;
}

.special .button {
  width: 370px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-color);
  transition: color 0.2s ease-in-out;
  font-weight: 600;
  letter-spacing: 0.125em;
  line-height: 1em;
  margin: 0 0 1em 0;
  text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.5em;
  line-height: 1.75em;
}

h3 {
  font-size: 1.25em;
  line-height: 1.5em;
}

h4 {
  font-size: 1.1em;
  line-height: 1.5em;
}

h5 {
  font-size: 0.9em;
  line-height: 1.5em;
}

h6 {
  font-size: 0.7em;
  line-height: 1.5em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
  border-bottom: solid 3px var(--accent-gray);
  margin: 3em 0;
}

hr.major {
  margin: 5em 0;
}

blockquote {
  border-left: solid 8px var(--accent-gray);
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

pre {
  -webkit-overflow-scrolling: touch;
  background: var(--transparent-gray-accent);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  line-height: 1.5em;
  margin: 0 0 2em 0;
  overflow-x: auto;
  padding: 1em 1.5em;
}

code {
  background:var(--transparent-gray-accent);
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Row */

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}

.row > * {
  box-sizing: border-box;
}

.row > .imp {
  order: -1;
}

.row {
  margin-top: 0;
  margin-left: -3em;
}

.row > * {
  padding: 0 0 0 3em;
}

.smallNote {
  font-size: 90%;
  font-weight: normal;
}

article {
  margin-top: 60px;
  margin-bottom: 120px;
}

article footer {
  color: var(--articel-footer);
  font-size: 10pt;
  display: block;
  clear: both;
  margin-top: 35px;
}

.container img {
  max-width: 100%;
  height: auto;
}

[class^="wp-block-"] figcaption {
  color: var(--caption-gray);
  font-size: 12px;
  font-size: 0.857142857rem;
  line-height: 2;
  font-style: italic;
  text-align: left;
}

.centeredsmall {
  max-width: 400px;
  margin: 0 auto;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

figure.size-full{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.nav-tabs {
  list-style: none;
}

.nav-tabs li {
  display: inline-block;
}


.wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
))::after 
,.extern-link::after {
  background-image: url('../assets/img/extern.svg');
  background-size: 14px;
  content: " ";
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-left: 2px;
  position: relative;
  top: 2px;
}

#nav .wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
)):hover::after
,#nav .extern-link:hover::after {
	filter: invert(100%);
}


#nav .wp-block-navigation-item__content[href]:not(:where(
  [href^="#"],
  [href^="/"]:not([href^="//"]),
  [href*="//telani.net"],
  [href*="//localhost:8000"]
))::after
,#nav .extern-link::after {
  filter: invert(80%);
  transition: filter 0.2s ease-in-out;
}

.center, .content p.center{
  text-align: center;
}

.alert{
  padding: 5px 15px;
  border: 1px solid var(--notice-color);
  background: var(--notice-background);
}

.alert.alert-success{
  border: 1px solid var(--success-color);
  background: var(--success-background);

}

.alert.alert-danger{
  border: 1px solid var(--error-color);
  background: var(--error-background);
}

.alert ul{
  margin: 0;
}

.clearfix {
  clear: both;
}

.has-error .g-recaptcha, .has-error .h-captcha {
  border: 1px solid var(--error-color);
  padding: 7px 5px;
}

.g-recaptcha, .h-captcha{
  margin: .25em 0;
}

.wp-block-image figure.alignright{
  float: right;
  padding: 0;
  display: table;
  margin: 0.857142857rem 0 0.857142857rem 1.714285714rem;
}

.wp-block-image figure.alignleft{
  float: left;
  padding: 0;
  display: table;
  margin: 0.857142857rem 1.714285714rem 0.857142857rem 0;
}

.register .small-text{
  margin-bottom: 16px;
}

@media (min-width: 920px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:first-child) {
    margin-left: var(--wp--style--block-gap,1em);
  }   
}

@media (min-width: 600px) and (max-width: 920px) {
  .wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:nth-child(2n) {
    margin: 0 auto 15px auto;
  }
}

/* Custom */

.register{
  display: flex;
}

.register section{
  padding: 0 30px;
  text-align: left;
}

#content .register p,#content .register li {
  text-align: left;
}

.register input[type="submit"]{
  display: block;
  margin: 30px auto 0;
}

.register input[type="checkbox"] + span::before{
  background: var(--page-background);
}

.register .centeredsmall{
  padding: 20px;
  max-width: 440px;
  border: solid 1px var(--articel-footer);
  background: var(--page-background-alt); 
}


@media screen and (max-width: 1680px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row {
    margin-top: 0;
    margin-left: -3em;
  }

  .row > * {
    padding: 0 0 0 3em;
  }
}

@media screen and (max-width: 1280px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }

}

@media screen and (max-width: 980px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }
}

@media screen and (max-width: 736px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .register {
    flex-direction: column;
  }

  .register .option-feature-description{
    display: none;
  }

  .row > * {
    box-sizing: border-box;
  }

  .row {
    margin-top: 0;
    margin-left: -2em;
  }

  .row > * {
    padding: 0 0 0 2em;
  }
}

@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: stretch;
  }

  .row > * {
    box-sizing: border-box;
  }
}

/* Container */

.container {
  margin: 0 auto;
  width: 1200px;
}

.container.medium {
  width: 900px;
}

.container.narrowcontainer {
  width: 960px;
}


@media screen and (max-width: 1680px) {
  .container {
    width: 1080px;
  }

  .container.medium {
    width: 810px;
  }

  .container.narrowcontainer {
    width: 960px;
  }
}

@media screen and (max-width: 1280px) {
  .container {
    width: 960px;
  }

  .container.medium {
    width: 720px;
  }

  .container.narrowcontainer {
    width: 900px;
  }
}

@media screen and (max-width: 980px) {
  .container {
    width: 90%;
  }

  .container.medium {
    width: 90%;
  }

  .container.narrowcontainer {
    width: 90%;
  }
}

@media screen and (max-width: 736px) {
  .container {
    width: 100%;
  }

  p {
    text-align: left;
  }

  .container.medium {
    width: 100%;
  }

  .container.narrowcontainer {
    width: 100%;
  }
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header.major {
  position: relative;
}

header.major::after {
  background: var(--accent-gray);
  content: '';
  display: inline-block;
  height: 3px;
  margin: 0 0 2em 0;
  width: 6em;
}

header.major.alt {
  text-align: center;
  margin-bottom: 2em;
}

header p {
  letter-spacing: 0.125em;
  margin: 0 0 1.5em 0;
  position: relative;
  text-transform: uppercase;
}

header h2 + p {
  font-size: 1em;
  margin-top: -0.85em;
  line-height: 1.75em;
}

header h3 + p {
  font-size: 0.9em;
  margin-top: -0.75em;
  line-height: 1.75em;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.8em;
  margin-top: -0.75em;
  line-height: 1.75em;
}

/* Form */

form {
  margin: 0 0 2em 0;
}

.fake-label, label {
  color: var(--header-color);
  display: block;
  font-size: 0.9em;
  font-weight: 600;
  
}

label {
  margin: 0 0 1em 0;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="number"].no-number-number,
select,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border: solid 2px var(--accent-gray);
  background-color: var(--form-background);
  border-radius: 0;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="search"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
input[type="date"]:invalid,
input[type="number"].no-number-number:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[readonly] {
  background: var(--form-disabled);
  font-style: italic;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
input[type="number"].no-number-number:focus,
select:focus,
textarea:focus {
  border-color: var(--link-color);
}

input[type="number"].no-number-number{
  appearance: textfield;
}

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23eee' /%3E%3C/svg%3E");
  background-size: 1.25em;
  background-repeat: no-repeat;
  background-position: calc(100% - 1em) center;
  height: 3.5em;
  padding-right: 3.5em;
  text-overflow: ellipsis;
}

select:focus::-ms-value {
  background-color: transparent;
}

select::-ms-expand {
  display: none;
}

select option {
  color: var(--header-color);
  background: var(--page-background);
}

input[type="text"],
input[type="password"],
input[type="search"],
input[type="email"],
input[type="date"],
input[type="number"].no-number-number,
select {
  height: 3.5em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label,
input[type="checkbox"] + span {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 300;
  padding-left: 2.85em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before,
input[type="checkbox"] + span::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-weight: 900;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before,
input[type="checkbox"] + span::before {
  background: var(--transparent-button-hover);
  border: solid 2px var(--caption-gray);
  content: '';
  display: inline-block;
  font-size: 0.8em;
  height: 2.1em;
  left: 0;
  line-height: 1.925em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.1em;
}

input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before,
input[type="checkbox"]:checked + span::before {
  background: var(--footer-background);
  border-color: var(--footer-background);
  color: #fff;
  /*content: '\f00c';*/
  content: '\2713';
}

input[type="checkbox"]:focus + label::before,
input[type="radio"]:focus + label::before,
input[type="checkbox"]:focus + span::before {
  border-color: var(--link-color);
}

input[type="radio"] + label::before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #bbb !important;
  opacity: 1;
}

::placeholder {
  color: #bbb !important;
  opacity: 1;
}

/* Box */

.box {
  border: solid 2px var(--accent-gray);
  margin-bottom: 2em;
  padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  padding: 0;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;

}

.icon > svg{
  height: 32px;
  width: 32px;
  vertical-align: middle;
}

.icon::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

.icon.solid::before {
  font-weight: 900;
}

.icon > .label {
  display: none;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px var(--accent-gray);
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 2em 0;
}

/* Table */

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px var(--accent-gray);
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.05);
}

table td {
  padding: 0.75em 0.75em;
}

table th {
  color: var(--header-color);
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: left;
}

table thead {
  border-bottom: solid 2px var(--accent-gray);
}

table tfoot {
  border-top: solid 2px var(--accent-gray);
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px var(--accent-gray);
  border-left-width: 0;
  border-top-width: 0;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button,
.wp-block-button{
  -webkit-appearance: none;
  appearance: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: var(--footer-background);
  border-radius: 0;
  border: 0;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 0.9em;
  font-weight: 600;
  
  letter-spacing: 0.125em;
  overflow: hidden;
  padding: 0.75em 1.75em;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;

}

/*
  white-space: nowrap;
  line-height: 4em;
  height: 4em;
*/

.wp-block-button__link {
  text-decoration: none;
}

.wp-block-button:not(.is-style-outline) .wp-block-button__link:not(.has-background){
  background: none !important;
}

.wp-block-button__link:not(.has-text-color){
  box-shadow: none !important;
}

.wp-block-button .wp-block-button__link{
  border: none !important;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover,
.wp-block-button:hover {
  background-color: var(--button-hover);
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active,
.wp-block-button:active {
  background-color: #121818;
}

input[type="submit"].icon,
input[type="reset"].icon,
input[type="button"].icon,
button.icon,
.button.icon,
.wp-block-button.icon {
  padding-left: 2.35em;
}

input[type="submit"].icon::before,
input[type="reset"].icon::before,
input[type="button"].icon::before,
button.icon::before,
.button.icon::before, 
.wp-block-button.icon::before {
  margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit,
.wp-block-button.fit {
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small,
.wp-block-button.small {
  font-size: 0.8em;
  height: 3.5em;
  line-height: 3.5em;
  padding: 0 2em;
}

input[type="submit"].large,
input[type="reset"].large,
input[type="button"].large,
button.large,
.button.large,
.wp-block-button.large {
  font-size: 1.25em;
  height: 3.15em;
  line-height: 3.15em;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt,
.wp-block-button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px var(--accent-gray);
  color: var(--header-color) !important;
}

input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
input[type="button"].alt:hover,
button.alt:hover,
.button.alt:hover,
.wp-block-button.alt::hover  {
  background-color: var(--transparent-button-hover);
}

input[type="submit"].alt:active,
input[type="reset"].alt:active,
input[type="button"].alt:active,
button.alt:active,
.button.alt:active,
.button.alt:active  {
  background-color: rgba(144, 144, 144, 0.1);
}

input[type="submit"].alt.icon::before,
input[type="reset"].alt.icon::before,
input[type="button"].alt.icon::before,
button.alt.icon::before,
.button.alt.icon::before,
.wp-block-button.alt.icon::before  {
  color: #bbb;
}

input[type="submit"].primary,
input[type="reset"].primary,
input[type="button"].primary,
button.primary,
.button.primary,
.wp-block-button.primary {
  background-color: var(--link-color);
  color: #fff !important;
}

input[type="submit"].primary:hover,
input[type="reset"].primary:hover,
input[type="button"].primary:hover,
button.primary:hover,
.button.primary:hover,
.wp-block-button.primary:hover {
  background-color: #ec7d75;
}

input[type="submit"].primary:active,
input[type="reset"].primary:active,
input[type="button"].primary:active,
button.primary:active,
.button.primary:active,
.wp-block-button.primary:active {
  background-color: #e65349;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled,
.wp-block-button:disabled {
  background-color: #4a4a4a !important;
  box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
  color: #fff !important;
  cursor: default;
  opacity: 0.25;
}

.wp-block-button .wp-block-button__link{
  font-weight: inherit !important;
  font-size: inherit !important;
}

.wp-block-buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

/* Wrapper */

.wrapper {
  padding: 6em 0 4em 0;
  transition: background 0.2s ease-in-out;
}

.wrapper.style2 {
  background: var(--page-background);
}

/* Main */

#main > header.major {
  margin: 0 0 2em 0;
  text-align: center;
}

/* Wide */

@media screen and (max-width: 1680px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }

  .textLogo {
    margin: 6px 0 0 0;
  }
}

/* Normal */

@media screen and (max-width: 1280px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }

  /* Wrapper */

  .wrapper {
    padding: 4em 0 2em 0;
  }

  /* Main */

  #main {
    padding: 2em 0;
  }
}

/* Narrow */

#navPanel,
#navButton {
  display: none;
}

@media screen and (max-width: 980px) {
  /* Basic */

  html,
  body {
    overflow-x: hidden;
  }

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  h2 {
    font-size: 1.25em;
    line-height: 1.75em;
  }

  h3 {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  h4 {
    font-size: 1em;
    line-height: 1.5em;
  }

  /* Section/Article */

  section.special,
  article.special {
    text-align: center;
  }

  header h2 + p {
    font-size: 0.9em;
  }

  header h3 + p {
    font-size: 0.8em;
  }

  header h4 + p,
  header h5 + p,
  header h6 + p {
    font-size: 0.8em;
  }

  /* Main */

  #main > header.major {
    margin: 0;
  }

  /* Off-Canvas Navigation */

  #navButton {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform 0.5s ease;
    display: block;
    height: 4em;
    left: 0;
    position: fixed;
    top: 0;
    width: 6em;
    z-index: 10001;
  }

  #navButton .toggle {
    text-decoration: none;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 13px;
    color: white;
    font-size: 24px;
  }

  #navPanel {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(-275px);
    transition: transform 0.5s ease;
    background-image: linear-gradient(left, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0.1));
    display: block;
    height: 100%;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 275px;
    z-index: 10002;
    background: var(--footer-background);
    color: #c7c9c9;
    padding: 0.25em 1.5em;
  }

  #navPanel .link {
    border-top: solid 1px rgba(255, 255, 255, 0.05);
    color: #c7c9c9;
    display: block;
    font-size: 0.8em;
    font-weight: 600;
    height: 3.5em;
    letter-spacing: 0.125em;
    line-height: 3.5em;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #navPanel .link:first-child {
    border-top: 0;
  }

  #navPanel .link.depth-0, #nav > ul > li > a {
    color: #fff;
  }

  #navPanel .link .indent-1 {
    display: inline-block;
    width: 1.25em;
  }

  #navPanel .link .indent-2 {
    display: inline-block;
    width: 2.5em;
  }

  #navPanel .link .indent-3 {
    display: inline-block;
    width: 3.75em;
  }

  #navPanel .link .indent-4 {
    display: inline-block;
    width: 5em;
  }

  #navPanel .link .indent-5 {
    display: inline-block;
    width: 6.25em;
  }

  body.navPanel-visible #page-wrapper {
    transform: translateX(275px);
  }

  body.navPanel-visible #navButton {
    transform: translateX(275px);
  }

  body.navPanel-visible #navPanel {
    transform: translateX(0);
  }
}

/* Mobile */

@media screen and (max-width: 736px) {
  /* Basic */

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  /* Wrapper */

  .wrapper {
    padding: 2.5em 1.25em 0.5em 1.25em;
  }

}

/* Mobile (Portrait) */

@media screen and (max-width: 480px) {
  /* Basic */

  html,
  body {
    min-width: 320px;
  }

  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }

  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br {
    display: none;
  }

  h2 {
    font-size: 1.15em;
    line-height: 1.75em;
  }

  h3 {
    font-size: 1em;
    line-height: 1.5em;
  }

  /* Button */

  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button,
  .button {
    padding: 0;
  }

}

.longbutton{
  font-size: 0.7em;
  letter-spacing: 0.08em;
}

p.has-text-align-center {
  text-align: center;
}

.wp-block-group.has-background{
  display: inline-block;
}

@import url("./twentytwelve.css");

@media screen and (max-width: 1000px) {
  .user-table {
    font-size: 8pt;
    display: block;
    overflow-x: auto;
  }  
}

svg {
  pointer-events: none;
}

.page-container{
  min-height: calc(100vh - 16em);
}

.small-margin-after {
	margin-bottom: 12px;
}

.medium-margin-after {
	margin-bottom: 30px;
}

.csvexportbutton{
  margin: 5px 10px;
  font-weight: normal;
}

.block{
  display: block;
}

#nav > ul {
  display: inline-block;
}

.entry-content p{
  hyphens: auto;
}

.telani-nav-tabs{
  margin-bottom: 2em;
}

.visible-link{
  text-decoration: underline;
}

h2.email-debug{
  text-align: center;
  margin-top: 2em;
}

.licenseItem {
    border: 2px solid var(--accent-gray);
    padding: 12px;
    margin: 8px 0;
    max-width: 600px;
}

.licenseItem form {
    margin: 0;
}

.invitationAccept{
  width: 270px;
  margin: 1em auto;
}

.miniNavLink.active {
  font-weight: bold;
}