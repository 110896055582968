.device-deactivated{
    background: #e1e1e1;
    color: #666;
}
  
.device-deactivated h2{
    color: #666;
}

.deviceActionDropDown{
    float: right;
}

.device .laptopArt{
    margin-right: 12px;
}
  
li.device {
    list-style: none;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px 12px;
}

.laptopArt {
    width: 100px;
    float: left;
  }