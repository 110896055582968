.legal-links li{
    display: inline-block;
    list-style: none;
  }
  
  .legal-links li a{
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;
    font-size: 0.8em;
  }
  
  .legal-links li a:hover{
    text-decoration: underline;
  }

  .copyright p{
    margin-bottom: .3em;
    text-align: center;
  }
  
#footer .copyright {
    line-height: 1.4em;
    font-size: 0.9em;
    margin: 2em 0 0 0;
    max-width: 700px;
    margin: 0 auto;
  }
  
  #footer .copyright a {
    color: var(--footer-text);
    transition: color 0.2s ease-in-out;
  }

  #footer {
    background: var(--footer-background);
    transition: background 0.2s ease-in-out;
    color: var(--footer-text);
    padding: 4em 0;
    text-align: center;
  }
  
  #footer .icons {
    padding-left: 0;
  }
  
  #footer .icons li {
    padding: 0 2em 0 0;
  }
  
  #footer .icons li:last-child {
    padding-right: 0;
  }
  
  #footer .icons li a {
    transition: color 0.2s ease-in-out;
    color: var(--footer-social);
    transition: color 0.2s ease-in-out;
  }
  
  #footer .icons li a:hover {
    color: var(--footer-social-hover);
  }

  ul.icons {
    cursor: default;
    list-style: none;
    padding-left: 0;
  }
  
  ul.icons li {
    display: inline-block;
    padding: 0 1em 0 0;
  }
  
  ul.icons li:last-child {
    padding-right: 0;
  }
  
  ul.icons li .icon::before {
    font-size: 1.5em;
  }

  .textLogo {
    margin: 10px 0 0 0;
    transition: opacity 500ms ease-in;
  }

  /* Header */

#header nav > ul#react-menu {
  margin: 0 0 0 2em;
}

#header nav > ul > li:hover > ul{
  display: block;
  position: absolute;
  right: 0em;
  top: 3em;
  background: var(--footer-background);
  list-style: none;
  padding: 5px 10px;
}

#header nav > ul > li > ul > li > a {
  display: block;
  line-height: 2.5em;
}

#header {
  background: var(--footer-background);
  color: #fff;
  cursor: default;
  height: 3.25em;
  left: 0;
  line-height: 3.25em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

#header h1 {
  color: #fff;
  height: inherit;
  left: 1.25em;
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

#header h1 a {
  font-size: 1em;
}

#header h1 a::before {
  color: var(--link-color);
  margin-right: 0.35em;
}

#header nav {
  font-weight: 600;
  height: inherit;
  letter-spacing: 0.125em;
  line-height: inherit;
  position: absolute;
  right: 1.5em;
  text-transform: uppercase;
  top: 0;
  vertical-align: middle;
}

#header nav > ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

#header nav > ul > li {
  display: inline-block;
  margin-left: 2em;
  padding-left: 0;
}

#header nav > ul > li a {
  transition: color 0.2s ease-in-out;
  color: var(--nav-text);
  display: inline-block;
  text-decoration: none;
  font-size: 0.8em;
}

#header nav > ul > li a::before, #header nav .svg-inline--fa {
  margin-right: 0.5em;
  opacity: 0.5;
}

#header nav > ul > li a:hover {
  color: var(--footer-social-hover);
}

#header nav > ul > li.active > a {
  color: var(--footer-social-hover);
}

#header nav > ul > li:first-child {
  margin-left: 0;
}

#header nav > ul > li input[type="submit"],
#header nav > ul > li input[type="reset"],
#header nav > ul > li input[type="button"],
#header nav > ul > li button,
#header nav > ul > li .button {
  font-size: 1em;
  height: 2.25em;
  line-height: 2.25em;
  margin-bottom: 0;
  padding: 0 1em;
  position: relative;
  top: -0.125em;
  vertical-align: middle;
}

#header nav > ul > li > ul {
  display: none;
}

#header .container {
  position: relative;
}

#header .container h1 {
  left: 0;
}

#header .container nav {
  right: 0;
}

/* Header */

#page-wrapper {
  padding-top: 0;
}



@media screen and (max-width: 980px) {
  #header nav > ul > li {
    display: block;
    margin-left: 0;
  }

  #header nav > ul > li > a {
    color: white;
  }

  #header nav{
    position: absolute;
    left: 0;
    right: unset;
  }

  #header nav .fa-angle-down{
    display: none;
  }

  #header nav > ul#react-menu{
    margin: 3.2em 0 0 0;
    height: calc(100vh - 3.2em);
    transform: translateX(-300px);
    transition: transform 0.5s ease;
    padding: 0 1.5em;
    min-width: 275px;
    overflow-y: scroll;
  }

  #header nav > ul > li > ul{
    display: block;
    margin: 0;
  }

  #header nav > ul > li:hover > ul, #header nav > ul > li:active > ul{
    display: block;
    position: unset;
    left: unset;
    top: unset;
    padding: 0 0 0 1em;
  }

  #header nav > ul > li > ul > li, #header nav > ul > li{
    list-style: none;
    line-height: 2.5em;
    border-top: 1px solid hsla(0,0%,100%,.05);
  }

  #header nav > ul > li > ul > li > a, #header nav > ul > li > a{
    line-height: inherit;
    letter-spacing: 0.125em;
  }

  #header nav > ul {
    background:  var(--footer-background);
  }

  #header nav > ul#react-menu.menu-open{
    transform: translateX(0);
  }

  #header h1 {
    left: unset;
    right: 1.25em;
    top: 2px;
  }
}



#page-wrapper {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.5s ease;
  padding-bottom: 1px;
}