.checklist {
    max-width: 300px;
    margin: 15px auto 0;
}

@media (prefers-color-scheme: dark) {
    .checklist li::before {
        filter: invert(1);
    }
}

.checklist li {
list-style-type: none;
margin-top: 5px;
}

.checklist li::before {
content: '';
display: inline-block;
height: 22px;
width: 20px;
background-size: 20px;
background-image: url("../../assets/img/checkbox.svg");
background-repeat: no-repeat;
background-position: 0 5px;
background-origin: content-box;
margin-right: 5px;
}

.register .checklist {
    max-width: 600px;
    margin: 15px auto 30px;
}