.addUserButton {
    margin: 1.3em 0 1em 0;
}

.poolAdminArea {
    margin: 3em;
}

.poolSubHeader {
    margin: 1em 0 1em 0;
}